
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { mdiChevronRight, mdiContentCut } from '@mdi/js';
import VideoClipListItem from './VideoClipListItem.vue';
import { VuetifyMixin } from '../../../mixins';
import { AssignmentModel } from '../../../models/course';
import { MarkupTagClipMuxModel } from '../../../models/markup-tag-clip-mux/MarkupTagClipMuxModel';

@Component({
	components: { VideoClipListItem },
})
export default class VideoClipList extends Mixins(VuetifyMixin){
	mdiContentCut = mdiContentCut;
	@Prop({ default: false }) private readOnly: boolean;
  @Prop({ default: () => ([]) }) private videoClips: MarkupTagClipMuxModel[];
  
  playButtonClickedOnClip(clipDetails: MarkupTagClipMuxModel) {
  	// console.log("In the VideoClipList - in the Play Button Clicked - clipDetails are: ", clipDetails);
  	this.$emit('playButtonClicked', clipDetails);
  }

}
