
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins';
import { Grade } from '../../../types/interfaces';
import GradeChip from './GradeChip.vue';

@Component({
	components: { GradeChip },
})
export default class GradeSelector extends Mixins(VuetifyMixin){

	@Prop({ default: true, type: Boolean }) clearable: boolean;
	@Prop({ default: false, type: Boolean }) disabled: boolean;
	@Prop({ default: 1 }) padding: number;

	@Prop() grade: Grade;
	selectGrade(grade): void{
		this.$emit('update:grade', grade);
	}

	show: boolean = false;
	toggleMenu(): void{
		this.show = !this.show;
	}

	@Prop() gradeSet: Grade[];

	get IsEmpty(): boolean{
		return this.grade === undefined || this.grade === null;
	}
	get Name(): string{
		if(this.grade){
			return this.grade.name;
		}
		return "Name";
	}
	get Color(): string{
		if(this.grade){
			return this.getColor(this.grade.color);
		}
		return "white";
	}

}
