
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { videoEditorStore } from '../../store'
import { mdiMapMarkerOutline } from '@mdi/js'


import VideoPlayer from './VideoPlayer.vue'
import { VideoJsPlayer } from 'video.js'
import VideoProgress from './VideoProgress.vue';
import VideoTimeline from './VideoTimeline.vue';
import VideoToolbar from './VideoToolBar.vue';
import { MarkupTagClipMuxModel } from '../../models/markup-tag-clip-mux/MarkupTagClipMuxModel'

@Component({
	components: {
		VideoPlayer,
		VideoProgress,
		VideoTimeline,
		VideoToolbar,
	},
	computed: {
		// ...mapState(['videoEditorStore']),
		// ...mapGetters('videoEditorStore', [
		//   'veSourceVideoURL',
		// ]),
		// ...mapActions('videoEditorStore', [
		//   'seekToTimeInSeconds()',
		//   'updateTimeSelf)()'
		// ])
	}
})
export default class VideoEditorOne extends Vue {
	mdiMapMarkerOutline = mdiMapMarkerOutline;

	// @Prop({ default: 450, type: Number }) height!: number;
	// @Watch('height') setPlayerHeight(height: number | null): void{
	// 	if(this.player){
	// 		this.player.height(height);
	// 	}
	// }

	// from - https://stackoverflow.com/questions/57416991/vue-typescript-how-to-avoid-ts-error-when-accessing-child-components-methods
	$refs: Vue["$refs"] & {
			videoPlayer: { player: VideoJsPlayer }
	}
	// Basing this work off of this Git Library showing usage of video.js 7:
	// https://github.com/alterhu2020/vue-videojs7

	playerOptions = {
		autoplay: false,
		controls: false,
		fluid: true,
		aspectRatio: '16:9',
		controlBar: {
			pictureInPictureToggle: false,
			fullscreenToggle: true,
			currentTimeDisplay: true,
			timeDivider: true,
			durationDisplay: true
		}
	}

	get viewingSingleClip(): boolean {
		return videoEditorStore.clipViewOnlyMode;
	}

	get calculatedWidth() {
		return videoEditorStore.calculatedWidth.toFixed(0)
	}

	get currentPlayerTime() {
		return videoEditorStore.playerCurrentTime.toFixed(1)
	}

	get player(): VideoJsPlayer {
		return this.$refs.videoPlayer.player as VideoJsPlayer
	}

	get theClipsForLoadedSV() : MarkupTagClipMuxModel[] {
		// console.log("getting the clips ... and they are ... ", videoEditorStore.markupTagClipsForSV)
		return videoEditorStore.markupTagClipsForSV;
	}

	get vidTotalTime() {
		return videoEditorStore.totalTime;
	}

	get vidLeftOffset() {
		return videoEditorStore.playerLeftOffset;
	}

	get vidPlayerWidth() {
		return videoEditorStore.playerWidth;
	}

	get isASourceVideoLoaded(): boolean {
		return (videoEditorStore.loadedSourceVideo !== null)
	}

	// onPlayerPlay() {
	//   // Do I need this ?
	//   console.log('player play!', this.player)
	// }

	onPlayerReady() {
		if (!videoEditorStore.thePlayer) {
			videoEditorStore.setVideoJSPlayer(
				// (this.$refs.videoPlayer as HTMLElement).player as VideoJsPlayer
				this.player
			)
		}

	}

	onTimeUpdate(e: any) {
		// console.log('got the timeUpdate event and it is: ', e.timeupdate)
		// console.log('got the timeUpdate and it is: ', this.player.currentTime())
		// console.log('The duration is: ', this.player.duration())
		// console.log('The dimenstions are: ' + this.player.dimension('width') + ', ' + this.player.dimension('height'))
		// console.log('The height is: ', this.player.height())
		// console.log('The Width is: ', this.player.width())
		// console.log('The isFullscreen value is: ', this.player.isFullscreen())
		// console.log('user is Scrubbing ?: ', this.player.scrubbing())
		// console.log('The video height is: ', this.player.videoHeight())
		// console.log('The video Width is: ', this.player.videoWidth())
		// console.log('The player current height is: ', this.player.currentHeight())
		// console.log('The player current Width is: ', this.player.currentWidth())

		// console.log('-----------------------------------------------------')

		// console.log('The player is this.player: ', this.player)
		// videoEditorStore.updateTimeSelf()
	}

	playButtonManual() {
		console.log('calling play button Manual ... ')
		// this.player.play()
		videoEditorStore.startPlaying()
	}

	pauseButtonManual() {
		console.log('calling pause button Manual ... ')
		// this.player.pause()
		videoEditorStore.pausePlaying()
	}

	randomEvent(eventName: string) {
		// console.log('A Player event occurred -- it was: ', eventName)

		// console.log('-----------------------------------------------------')
	}

	async onStateChangedEvent(e: any) {
		const theEventArray: any[] = Object.keys(e)
		if (theEventArray.length > 1) {
			console.log(
				'!!!!!!!!**********  THERE IS MORE THAN ONE EVENT IN THE STATE CHANGED EVENT *********!!!!!!!!!!!!'
			)
		}
		switch (theEventArray[0]) {
		case 'timeupdate':
			// {
			// console.log('got the timeUpdate event and it is: ', e.timeupdate)
			// console.log('-----------------------------------------------------')

			// const timeTemp: number = e.timeupdate
			// if (timeTemp > 5.0 && timeTemp < 59.0) {
			//   videoEditorStore.seekToTimeInSeconds(60)
			// } else {
			videoEditorStore.updateTimeSelf();
			// }
			// }
			break
		case 'pause':
			// console.log('got the pause event at current time: ', this.player.currentTime())
			// console.log('got the pause event the current player type is: ', this.player.currentType())
			// console.log('-----------------------------------------------------')
			break
		case 'canplay':
			// console.log('A STATE CHANGE got the canplay event')
			// console.log('-----------------------------------------------------')
			videoEditorStore.canPlayHandler();
			break
		case 'playerresize':
			// console.log('got the ' + Object.keys(e)[0] + ' event')
			// console.log('The player size is: ', this.player.currentDimensions())
			// console.log('-----------------------------------------------------')
			await videoEditorStore.setPlayerWidth(this.player.currentDimension('width'));
			// console.log('Can i get the offset here ??  ', (this.$refs.veoTopLevelDivRef as HTMLDivElement).offsetLeft)
			break
		case 'loadeddata':
			// console.log('got the ' + Object.keys(e)[0] + ' event')
			// console.log('The player duration is: ', this.player.duration());
			// console.log('-----------------------------------------------------')
			await videoEditorStore.loadedMetaDataHandler();
			// this happens once per load up ... so signal the parent to reset the offset here should be sufficient.
			this.$emit('update:resizeEvent');
			break
		case 'ended':
			// console.log('got the Ended event')
			// console.log('-----------------------------------------------------')
			videoEditorStore.playerEndedEvent();
			break
		default:
			// console.log('A StateChanged event occurred -- it was: ', e)
			break
		}
	}

	onKeydownEvent(e: any) {
		console.log('A Key was pressed: ', e.keydown)

		console.log('-----------------------------------------------------')
	}

	onResizeEvent() {
		// console.log('Resize event just occurred - new player size.')
		// console.log('The player current height is: ', this.player.currentHeight())
		// console.log('The player current Width is: ', this.player.currentWidth())

		// console.log('-----------------------------------------------------')
		videoEditorStore.setPlayerWidth(this.player.currentWidth());

	}

	playVideo(source: string) {
		const video = {
			withCredentials: false,
			type: 'application/x-mpegurl',
			src: source
		}
		this.player.reset() // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
		this.player.src(video)
		// // this.player.load()
		// this.player.play()
		// this.player.volume(0.1)
	}

	// switchPlayer() {
	//   this.playVideo(this.src)
	// }


	tagClipWasClicked(e: any, theIndex: number) {
		// console.log('A tag was clicked and the event was: ', e)
		// console.log(
		//   'The tag at index ' + theIndex + ' is: ',
		//   this.theClipsForLoadedSV[theIndex]
		// )
		// console.log(
		//   'The left position should be ... ',
		//   (this.theClipsForLoadedSV[theIndex].markStart / this.vidTotalTime) *
		//     this.vidPlayerWidth
		// )
		videoEditorStore.gotClickOnATagClipImage(
			this.theClipsForLoadedSV[theIndex]
		)
	}

	// async mounted() {
	//   console.log('In the VideoEditorOne mounted routine and the divPlayerContainerWidth is: ', this.player.currentDimension('width'));

	//   // const src = 'https://stream.mux.com/KvtwXKpfHFtNqW8FgLco8abB4GuYuGAp.m3u8'
	//   // const src = 'https://stream.mux.com/rx8foYWZWoN01007x1zSfAh4e7zBikenr2.m3u8'
	//   // const src = 'https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8'
	//   // this.playVideo(src)

	//   // const placeholderURL = "https://firebasestorage.googleapis.com/v0/b/video-manager-demo-one.appspot.com/o/Placeholders%2FVideoPlaceholderImage854x480.png?alt=media&token=1bea764e-c2e8-4c54-bbb9-97f026c191f6";
	// }

	async beforeDestroy() {
		// console.log(' ***************************************** ')
		// console.log(' in Before Destroy on the Video Editor One. ')
		// console.log(' tell the VideoEditor store that the player is going out of scope ')
		videoEditorStore.videoJSPlayerRemove();
		// console.log(' ***************************************** ')
	}
}
