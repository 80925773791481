
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { ContextMenuItem } from '../../../types/interfaces';
import { mdiAccountMultipleOutline, mdiLink, mdiPencil, mdiCloseCircle, mdiTrashCanOutline, mdiFileDocumentOutline } from '@mdi/js';
import { VuetifyMixin } from '../../mixins';
import Alert from '../ui/Alert.vue'
import CopyToClipboard from '../ui/CopyToClipboard.vue'
import ConfirmationDialog from '../ui/ConfirmationDialog.vue'
import ContextMenu from '../ui/ContextMenu.vue'
import UserChip from '../ui/UserChip.vue'
import TextEditor from '@/components/courses/TextEditor.vue'
import ProfilePictureProvider from '../hoc/ProfilePictureProvider.vue'
import WhistleSvg from '../svg/WhistleSvg.vue'

import { CourseModel } from '../../models/course';
import { formatDateSlashes } from '../../helpers';
import { StaffMember } from '../../models/team/StaffMember';
import { coursesStore } from '../../store';


@Component({
	components: { Alert, CopyToClipboard, ConfirmationDialog, ContextMenu, TextEditor, UserChip, ProfilePictureProvider, WhistleSvg },
})
export default class CourseHeader extends Mixins(VuetifyMixin){
	mdiLink = mdiLink;
	mdiFileDocumentOutline = mdiFileDocumentOutline;
	mdiAccountMultipleOutline = mdiAccountMultipleOutline;
	mdiTrashCanOutline = mdiTrashCanOutline;
	
	showLinkDialog: boolean = false;
	showInstructorLinkDialog: boolean = false;
	showCourseDetailsDialog: boolean = false;

	@Prop({ default: 'baColorDeepBlue' }) private color: string;
	get Color(): string{
		if(this.course && this.course.color) return this.course.color;
		return this.color;
	}

	@Prop({ default: 285 }) private height: number;
	@Prop({ required: true }) private course: CourseModel;
	@Prop() private studentJoinUrl: string | null;
	@Prop() private staffJoinUrl: string | null;
	@Prop({ type: Boolean, default: false }) private card: boolean;
	@Prop({ type: Boolean, default: false }) private rounded: boolean;
	@Prop({ type: Boolean, default: false }) private loading: boolean;
	@Prop({ type: Boolean, default: false }) private hideCourseDetails: boolean;

	@Prop({ type: Boolean, default: false }) private showInstructors: boolean;
	@Prop({ type: Boolean, default: false }) private hideStudents: boolean;

	get Loading(): boolean{
		return this.loading || this.course === null || this.course === undefined;
	}
	get CourseDetails(): string | null{
		if(!this.course) return null;
		return this.course.details;
	}
	ContextMenuItems: ContextMenuItem[] = [
		{
			icon: mdiLink,
			text: "Get Instructor Link",
			click: (): void => this.showInstructorLink(),
		},
		{
			icon: mdiPencil,
			text: "Edit Course",
			click: (): void => this.editCourse(),
		},
		{
			icon: mdiCloseCircle,
			text: "Delete Course",
			click: (): void => this.confirmDeleteCourse(),
		}
	];

	showInstructorLink(): void{
		this.showInstructorLinkDialog = true;
	}

	editCourse(): void{
		this.$router.push({
			name: 'EditCourse',
			params:{
				courseId: this.course.id,
			}
		})
	}
	showDeleteDialog: boolean = false;
	deleteValid: boolean = false;
	confirmDeleteCourse(): void{
		this.showDeleteDialog = true;
		this.deleteValid = false;
	}
	async deleteCourse(): Promise<void>{
		await coursesStore.deleteCourse(this.course);
		this.$router.push({
			name: 'CoachDashboard',
		});
	}
	get ConfirmDeleteTitle(): string{
		return `Delete "${this.course.name}"?`;
	}
	get DeleteCourseLoading(): boolean{
		return coursesStore.deleteCourseLoading;
	}
	deleteRule = [
		(v: string): string | boolean => v && v.toLowerCase() === 'delete' || false,
	];

	get StartDateFormatted(): string{
		return formatDateSlashes(this.course.startDate);
	}
	get EndDateFormatted(): string{
		return formatDateSlashes(this.course.endDate);
	}

	get CourseStaffMembersWithoutOwner(): StaffMember[]{
		return this.course.StaffMembersWithoutOwner;
	}
}
