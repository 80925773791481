
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins';
import { AssignmentModel } from '../../models/course';

@Component
export default class AssignmentChip extends Mixins(VuetifyMixin){

	@Prop({ default: false, type: Boolean }) private dark: boolean;
	@Prop({ default: 55 }) private height: number;
	@Prop({ default: 'primary' }) private color: string;
	@Prop({ default: 'baColorSuperLightBlue' }) private backgroundColor: string;
	@Prop() private assignment?: AssignmentModel;

	get SizePx(){
		return `${this.height}px`;
	}
	get InnerSizePx(){
		return `${this.height - 2}px`;
	}
	get IconStyle(){
		return {
			'border': `1px solid ${this.getColor(this.AssignmentColor)}`,
			'background-color': `${this.getColor(this.AssignmentColor)}`,
			'width': this.InnerSizePx,
			'height': this.InnerSizePx,
			'border-radius': this.SizePx,
			'line-height': this.InnerSizePx,
		};
	}
	get ChipStyle(){
		return {
			'color': `${this.getColor(this.dark ? 'white' : 'baColorPrimaryText')}`,
			'background-color': `${this.getColor(this.BackgroundColor)}`,
			'border': `1px solid ${this.getColor(this.dark ? 'baColorGrayBackground' : this.AssignmentColor)}`,
			'border-radius': this.SizePx,
			'height': this.SizePx,
			'line-height': this.SizePx,
			'font-weight': '500',
			'font-size': '14px',
		};
	}

	get AssignmentColor(){
		if(this.assignment === undefined) return this.color;
		return this.assignment.color;
	}
	get BackgroundColor(){
		if(this.assignment === undefined) return this.backgroundColor;
		if(this.dark) return 'baColorGrayBackground';
		return this.assignment.backgroundColor;
	}
	get AssignmentIdentifier(){
		if(this.assignment === undefined) return "";
		return this.assignment.identifier;
	}
	get AssignmentName(){
		if(this.assignment === undefined) return "";
		return this.assignment.name;
	}
}
