
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { mdiPencil } from '@mdi/js';
import { RubricFormData, Evaluation, Grade, RubricCriteriaGroup } from '../../../types/interfaces';
import { VuetifyMixin, MyCoachMixin, AuthMixin } from '../../mixins';
import { AssignmentModel, RubricModel } from '../../models/course';
import CircledAvatar from '../ui/CircledAvatar.vue';
import GradeSelector from '../courses/GradeSelector.vue';
import Discussion from '../courses/Discussion.vue';
import SingleCommentTextArea from '../courses/SingleCommentTextArea.vue';
import TextEditor from '../courses/TextEditor.vue';
import CriteriaGroupVideoClips from './CriteriaGroupVideoClips.vue';
import RubricGroupHeader from './RubricGroupHeader.vue';
import { MarkupTagClipMuxModel } from '../../models/markup-tag-clip-mux/MarkupTagClipMuxModel';
import { gradeToColor } from '../../pipes';


@Component({
	components: {
		CircledAvatar,
		CriteriaGroupVideoClips,
		Discussion,
		TextEditor,
		RubricGroupHeader,
		GradeSelector,
		SingleCommentTextArea
	},
})
export default class RubricForm extends Mixins(VuetifyMixin, MyCoachMixin, AuthMixin){
	mdiPencil = mdiPencil;

	@Prop({ default: 'baColorSuperLightBlue' }) color: string;
	@Prop({ default: 'baColorPrimaryBlue' }) borderColor: string;
	@Prop({ default: false, type: Boolean }) readOnly: boolean;
	@Prop({ default: false, type: Boolean }) stickyCategories: boolean;
	@Prop({ default: false, type: Boolean }) multiple: boolean;
	@Prop({ default: null }) rubric: RubricModel | null;
	@Prop({ required: true }) assignment: AssignmentModel;
	@Prop({ required: true }) evaluation: Evaluation;
	updateEvaluation(value: Evaluation): void{
		this.$emit('update:evaluation', value);
	}

	get UseVideoReview(): boolean{
		return this.assignment.useVideoReview;
	}

	get FinalOutcomeTitle(): string{
		if(this.readOnly){
			return "Your Final Outcome";
		}
		return "Student's Final Outcome";
	}
	get MentorNotesLabel(): string{
		return "Mentor Notes"
	}
	get StudentNotesLabel(): string{
		if(this.readOnly){
			return 'Your Notes';
		}
		return "Student's Notes"
	}

	@Prop() step: number;
	updateStep(value: number): void{
		this.step = value;
		this.$emit('update:step', value);
	}

	updateFormValue(): void{
		const evaluation: Evaluation = this.formValueToEvaluation(this.formValue);
		this.updateEvaluation(evaluation);
		this.updateFormValid();
	}

	getGroupDiscussionId(group: RubricCriteriaGroup): string{
		return `${this.assignment.id}_${group.id}`;
	}
	getGroupCommentId(group: RubricCriteriaGroup, role: string): string{
		return `${this.assignment.id}_${group.id}_${role}`;
	}

	nextStep(): void{
		this.updateStep(this.step + 1);
	}

	get RubricGroups(): RubricCriteriaGroup[]{
		if(this.rubric){
			return this.rubric.criteriaGroup;
		}
		return [];
	}
	get RubricCategories(): string[]{
		if(this.rubric){
			return this.rubric.categories;
		}
		return [];
	}

	get NoStepSelected(): boolean{
		return this.step === undefined;
	}

	get ShowCategories(): boolean{
		return this.NoStepSelected;
	}

	formValue: RubricFormData = {
		gradesMatrix: [],
	};
	setupForm(): void{
		this.formValue = this.evaluationToRubricFormData(this.assignment.evaluation);
		this.updateFormValue();
	}
	mounted(): void{
		this.setupForm();
	}

	getEmptyGradesMatrix(): Grade[][]{
		if(this.rubric === null) return [];
		return new Array<Grade>(this.rubric.criteriaGroup.length).fill(null).map(() => new Array<Grade>(this.rubric.categories.length).fill(null));
	}

	updateGradeSelection(): void{
		this.updateFormValue();
	}

	@Watch('FormValid') updateFormValid(): void{
		this.$emit('update:valid', this.FormValid);
	}
	get FormValid(): boolean{
		return this.AllCriteriaGroupsValid && (!this.addFinalOutcome || (this.addFinalOutcome && this.FinalOutcomeValid));
	}
	get AllCriteriaGroupsValid(): boolean{
		return this.CriteriaGroupsValid.reduce((a,b) => a && b, true);
	}
	get CriteriaGroupsValid(): boolean[]{
		return this.formValue.gradesMatrix.map(group => {
			return group.reduce((a,b) => a !== null && b !== null, true);
		});
	}
	get FinalOutcomeValid(): boolean{
		return this.finalOutcomeGrade !== null;
	}

	evaluationToRubricFormData(evaluation: Evaluation | null): RubricFormData{
		const rubricFormData: RubricFormData = {
			gradesMatrix: this.getEmptyGradesMatrix(),
		};
		if(evaluation === null || evaluation === undefined){
			return rubricFormData;
		}
		if(evaluation.finalOutcome !== null && evaluation.finalOutcome !== undefined){
			this.addFinalOutcome = true;
			this.finalOutcomeGrade = evaluation.finalOutcome;
		}
		for(const categoryGrade of evaluation.categoryGrades){
			const groupIndex = this.rubric.criteriaGroup.findIndex(g => g.id == categoryGrade.criteriaGroupId);
			if(groupIndex === -1) continue;
			const categoryIndex = this.rubric.categories.findIndex(category => category == categoryGrade.category);
			if(categoryIndex === -1) continue;
			rubricFormData.gradesMatrix[groupIndex][categoryIndex] = {
				...categoryGrade.grade,
			};
		}
		return rubricFormData;
	}
	formValueToEvaluation(formValue: Record<string, any>): Evaluation{
		const evaluation: Evaluation = {
			categoryGrades: [],
			finalOutcome: this.finalOutcomeGrade,
		};
		for(const i in formValue.gradesMatrix){
			for(const j in formValue.gradesMatrix[i]){
				evaluation.categoryGrades.push({
					category: this.rubric.categories[j],
					criteriaGroupId: this.rubric.criteriaGroup[i].id,
					grade: formValue.gradesMatrix[i][j],
				});
			}
		}
		return evaluation;
	}

	playButtonClickedOnClip(clipDetails: MarkupTagClipMuxModel): void {
		this.$emit('showAClip', clipDetails);
	}

	get ShowAddFinalOutcome(): boolean{
		return !this.readOnly && this.AllCriteriaGroupsValid && this.UseVideoReview;
	}
	get ShowFinalOutcome(): boolean{
		return this.addFinalOutcome || this.finalOutcomeGrade !== null;
	}
	addFinalOutcome: boolean = false;
	finalOutcomeUpdated(value: boolean): void{
		if(value === false) this.finalOutcomeGrade = null;
		this.addFinalOutcome = value;
	}
	finalOutcomeGrade: Grade | null = null;

	get FinalOutcomeColor(): string | undefined{
		if(this.finalOutcomeGrade === null){
			return undefined;
		}
		return this.getColor(gradeToColor(this.finalOutcomeGrade.name, { background: false }));
	}
	get FinalOutcomeColorBG(): string | undefined{
		if(this.finalOutcomeGrade === null){
			return undefined;
		}
		return this.getColor(gradeToColor(this.finalOutcomeGrade.name, { background: true }));
	}

}
