
import { Component, Vue } from 'vue-property-decorator'

import { videoEditorStore } from '../../store'

@Component({
	components: {
	},
	computed: {
	}
})

export default class VideoProgress extends Vue {

	get calculatedWidth() {
		return videoEditorStore.calculatedWidth.toFixed(0)
	}

	get isDragging() {
		return videoEditorStore.isDragging
	}

	// This is the progress bar
	seekVideo (e: MouseEvent) {
		console.log('A clck was seen in the timeline - is dragging = ' + this.isDragging + ' & the event is: ', e)
		if ((e.srcElement as HTMLDivElement).id !== 'thumbScrubber') {
			videoEditorStore.seekToPixelWidthInProgressBar(e.offsetX)
		}
	}

	dragStart (e: MouseEvent) {
		console.log('The drag start has occurred, and it is: ', e)
		videoEditorStore.dragStart(e)
	}
}
