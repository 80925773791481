
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { videoEditorStore } from '../../store';
import { mdiMapMarkerOutline } from '@mdi/js';
import { formatDurationShort, formatDurationForTimeline } from '../../helpers';
import { VuetifyMixin } from '../../mixins';

@Component({
	components: {
	},
	computed: {
	}
})

export default class VideoTimeline extends Mixins(VuetifyMixin) {

	mdiMapMarkerOutline = mdiMapMarkerOutline;

	get isViewClipOnly(): boolean {
		return videoEditorStore.clipViewOnlyMode;
	}

	get calculatedWidth() {
		return videoEditorStore.calculatedWidth.toFixed(0);
	}

	get mainScrubberFillColor() {
		if (this.isViewClipOnly) {
			return `${this.getColor('baColorLightBlue')}`;
		} else {
			return `${this.getColor('baColorAccentGold')}`;
		}
	}

	get currentScrubState() {
		return videoEditorStore.scrubState;
	}

	get leftEdgeStart() {
		return videoEditorStore.playerLeftOffset;
	}

	get startMarkerPosition() {
		return videoEditorStore.startMarkerPosition.toFixed(0);
	}

	get stopMarkerPosition() {
		return videoEditorStore.stopMarkerPosition.toFixed(0);
	}

	get isDragging() {
		return videoEditorStore.isDragging;
	}

	get scrubState() {
		return videoEditorStore.scrubState;
	}

	get numberOfLargeTickSections() {
		// console.log("The number of large main segments is - ", videoEditorStore.numMainSegments)
		const tempNum = videoEditorStore.numMainSegments;
		if (Object.is(NaN, tempNum)) {
			return 0;
		} else {
			return tempNum;
		}
	}

	get numberOfSmallTickSections() {
		const tempNum = videoEditorStore.smallToLargeDivisions;
		if (Object.is(NaN, tempNum)) {
			return 0;
		} else {
			return tempNum;
		}
	}

	get textLineSkipFactor() {
		return videoEditorStore.textTickSkipFactor;
	}

	get largeTickSectionWidth() {
		return videoEditorStore.mainSegmentsWidthInPixels / videoEditorStore.numMainSegments;
	}

	get smallTickSectionWidth() {
		return this.largeTickSectionWidth / videoEditorStore.smallToLargeDivisions;
	}

	timeDisplayAtLargeTick(tickNumber: number): string {
		return formatDurationForTimeline(videoEditorStore.scaleDensityCurrent * tickNumber);
	}

	// This is the progress bar
	seekVideo (e: MouseEvent) {
		// console.log('A clck was seen in the timeline - is dragging = ' + this.isDragging + ' & the event is: ', e);

		const eId = (e.srcElement as HTMLDivElement).id;
		if ( eId !== 'thumbScrubber' && eId !== 'rightWing' && eId !== 'leftWing') {      // not sure this if statement is necessary - I think the @click.self will eliminate the need for this.
			videoEditorStore.seekToPixelWidthInProgressBar(e.clientX - videoEditorStore.playerLeftOffset);
		}
	}

	seekVideoInHiglightSection (e: MouseEvent) {
		if ( (e.srcElement as HTMLDivElement).id === 'clipLengthHighlight') {
			videoEditorStore.seekToPixelWidthInProgressBar(e.offsetX + videoEditorStore.startMarkerPosition);
		}
	}

	seekBackToClipStart (e: MouseEvent) {
		if ( (e.srcElement as HTMLDivElement).id === 'clipLengthHighlight') {
			videoEditorStore.seekToPixelWidthInProgressBar(videoEditorStore.startMarkerPosition);
		}
	}

	dragStart (e: MouseEvent) {
		// console.log('The drag start has occurred, and it is: ', e)
		videoEditorStore.dragStart(e);
	}

	resetScrubber() {
		videoEditorStore.resetScrubberToWhole();
	}

	resetLeftDragHandle() {
		videoEditorStore.resetLeftDragHandle();
	}

	resetRightDragHandle() {
		videoEditorStore.resetRightDragHandle();
	}
}
