
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins';
import ProgressBar from '../charts/ProgressBar.vue';

@Component({
	components: { ProgressBar },
})
export default class GradeChip extends Mixins(VuetifyMixin){

	@Prop({ default: false, type: Boolean }) private empty: boolean;
	@Prop({ default: 100, type: Number }) private progress: number;
	@Prop({ default: 'baColorSuperLightBlue' }) private color: string;
	@Prop({ default: 'baColorPrimaryBlue' }) private borderColor: string;
	@Prop({ default: '1' }) private padding: number;

	get Color(): string{
		if(this.empty){
			return 'white';
		}
		return this.getColor(this.color);
	}
	get EmptyPadding(): string{
		return `pa-${this.padding}`;
	}
}
