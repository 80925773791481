import { render, staticRenderFns } from "./CourseHeader.vue?vue&type=template&id=53ee6f42&scoped=true&"
import script from "./CourseHeader.vue?vue&type=script&lang=ts&"
export * from "./CourseHeader.vue?vue&type=script&lang=ts&"
import style0 from "./CourseHeader.vue?vue&type=style&index=0&id=53ee6f42&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53ee6f42",
  null
  
)

export default component.exports