
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin, MyCoachMixin, DebounceMixin } from '../../mixins';
import TextEditor from './TextEditor.vue';
import { CommentModel } from '../../models/discussion/CommentModel';
import { DiscussionModel } from '../../models/discussion/DiscussionModel';
import { discussionApi, DiscussionApi } from '../../api/DiscussionApi';
import { formatDateLastModified } from '../../helpers';

@Component({
	components: {
		TextEditor,
	}
})
export default class SingleCommentTextArea extends Mixins(DebounceMixin, VuetifyMixin, MyCoachMixin) {
	formatDateLastModified = formatDateLastModified;
	@Prop({ type: Boolean, default: false }) instructor: boolean;
	@Prop({ type: Boolean, default: false }) disabled: boolean;
	@Prop({ type: String, required: true }) discussionId: string;
	@Prop({ type: String, required: true }) commentId: string;

	/**
	 * The editor's content is set when the component initialized, and isn't updated after.
	 * So we can't render the component until the comment is ready.
	 */
	get ContentReady(): boolean{
		return this.Discussion !== null && this.Comment !== null;
	}

	get Loading(): boolean{
		return this.discussionLoading || this.commentLoading;
	}

	get IsEmpty(): boolean{
		return this.disabled && (!this.commentText || this.commentText.length === 0);
	}

	discussionLoading: boolean = false;
	discussion: DiscussionModel | null = null;
	private async setupDiscussion(){
		this.discussionLoading = true;
		this.discussion = await discussionApi.findById(this.discussionId).catch(() => null);
		if(this.discussion === null){
			this.discussion = await discussionApi.save(new DiscussionModel().load({
				id: this.discussionId,
			})).catch(() => null);
		}
		await this.setupComment(this.discussion);
		this.discussionLoading = false;
	}

	comment: CommentModel | null = null;
	commentLoading = true;
	commentText: string = "";
	private async setupComment(discussion: DiscussionModel){
		this.commentLoading = true;
		this.comment = await DiscussionApi.commentApi.findById(this.commentId).catch(() => null);
		if(this.comment === null){
			this.comment = await DiscussionApi.commentApi.save(new CommentModel().load({
				id: this.commentId,
			})).catch(() => null);
			discussion.setComment(this.comment);
			await discussionApi.save(discussion);
		}
		this.commentText = this.comment.body;
		this.commentLoading = false;
	}

	private async updateComment(value: string){
		this.comment.body = value;
		this.debounceCallback('saveComment', () => {
			this.save();
		}, 2000);
	}

	get Discussion(): DiscussionModel | null{
		return this.discussion;
	}
	get Comment(): CommentModel | null{
		return this.comment;
	}
	get CommandLastModified(): string | null{
		if(this.Comment === null) return null;
		return formatDateLastModified(this.Comment.lastModified);
	}

	private async saveNow(){
		this.debounceCancelCallback('saveComment');
		await this.save();
	}

	private async save(){
		this.commentLoading = true;
		try{
			this.comment = await DiscussionApi.commentApi.save(this.comment);
		}catch(e){
			console.error("Failed to save comment", e);
		}
		this.commentLoading = false;
	}

	created(): void{
		this.setupDiscussion();
	}
}
