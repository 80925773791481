
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { mdiChevronDown, mdiPencil } from '@mdi/js';
import { VuetifyMixin } from '../../mixins';

import GradeSelector from './GradeSelector.vue';
import CircledAvatar from '../ui/CircledAvatar.vue';
import { RubricCriteriaGroup, Grade } from '../../../types/interfaces';

@Component({
	components: { CircledAvatar, GradeSelector },
})
export default class RubricGroupHeader extends Mixins(VuetifyMixin){
	mdiChevronDown = mdiChevronDown;
	mdiPencil = mdiPencil;
	@Prop() criteriaGroup: RubricCriteriaGroup;
	@Prop() index: number;
	@Prop() icon: string;
	@Prop({ default: false, type: Boolean }) isOpen: boolean;
	@Prop({ default: false, type: Boolean }) readOnly: boolean;
	@Prop({ default: false, type: Boolean }) disabled: boolean;
	@Prop({ default: false, type: Boolean }) isValid: boolean;
	@Prop({ default: [] }) rubricCategories: string[];
	get categoryCount(): number{
		return this.rubricCategories.length;
	}
	get CategoryCols(): number{
		return ~~(12 / this.rubricCategories.length);
	}
	@Prop() gradeSet: Grade[];

	@Prop({ default: () => ([]) }) gradeSelection: Grade[];

	updateGrade(): void{
		this.$emit('update:gradeSelection', this.gradeSelection);
	}
	get ShowCategoryHeader(): boolean{
		return this.isOpen;
	}
	get RowComplete(): boolean{
		return this.isValid && !this.disabled;
	}
	get IconBackgroundColor(): string{
		if(!this.RowComplete){
			if(this.isOpen){
				return this.getColor('baColorVibrantBlue');
			}
			return this.getColor('baColorGray1');
		}
		return this.getColor('baColorVibrantBlue');
	}

	get HeaderColors(): Record<string, string>{
		if(this.isOpen){
			return {
				'color': this.getColor('baColorPrimaryText'),
				'font-weight': '500',
				'background-color': this.getColor('white'),
				'box-shadow': `0px 5px 5px rgba(0, 0, 0, 0.13)`,
			};
		}
		if(!this.RowComplete){
			return {
				'color': this.getColor('baColorSecondaryText'),
				'background-color': this.getColor('white'),
				'border': `1px solid ${this.getColor('baColorGray8')}`
			};
		}
		return {
			'color': this.getColor('baColorDeepBlue'),
			'background-color': this.getColor('white'),
			'border': `1px solid ${this.getColor('baColorGray8')}`
		};
	}

	get HeaderStyle(): Record<string, string>{
		return {
			...this.HeaderColors,
		};
	}

}
